<template>
  <CRow>
    <CCol cols="12" lg="12" class="pb-0 pt-0">
      <b-table class="data_table"
              :data="leaderboardData"
              :striped="true"
              :hoverable="true"
              :mobile-cards="true"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"
              detailed 
              detail-key="user_id_external" 
              :show-detail-icon="true"
              :default-sort-direction="defaultSortDirection"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="completed.courses_completed">
        
        <template slot-scope="props">
          <b-table-column field="image" width="2.5%">
            <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.name" :userProfileImage="props.row.user_profile_image" :userIdExternal="props.row.user_id_external" :senderIdExternal="null" popoverPlacement="right"/>          
          </b-table-column>
          <b-table-column field="user_name" :label="$t('common.Employee')" :searchable="searchEnabled" :sortable="true" width="50%">
            <div class="d-flex flex-column">
              <span><b>{{props.row.user_name}}</b></span>
              <div class="mt-1 meta">
                <span>{{props.row.user_department}} - {{props.row.user_team}}</span>
              </div>              
            </div>
          </b-table-column>
          <b-table-column field="courses_completed" :label="$t('insights.Courses_completed')" :searchable="searchEnabled" :sortable="true" width="25%">
            <span>{{props.row.courses_completed}} {{props.row.courses_completed !== 1 ? $t('common.courses') : $t('common.course')}}</span>
          </b-table-column>
          <b-table-column field="last_finished_course_date" :label="$t('insights.Last_course_finished')" :searchable="searchEnabled" :sortable="true" width="25%">
            <span>{{props.row.last_finished_course_date}}</span>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{ $t('insights.No_attendees_found') }}</span>
          </div>
        </template>
        <template slot="detail" slot-scope="props">
          <b-table class="data_table" 
                    :data="Object.values(props.row.courses)" 
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="false"
                    default-sort-direction='desc'
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="course_finished_date">
            
            <template slot-scope="props">
              <b-table-column field="image" width="2.5%">
                <div v-if="props.row.course_image_id && props.from_marketplace === 'N'"
                     class="item_image d-inline-block align-middle"
                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }">
                </div>
                <div v-else-if="props.row.course_image_id && props.from_marketplace === 'Y'"
                     class="item_image d-inline-block align-middle"
                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + props.row.lms_course_id_external + '/' + props.row.course_image_id + '.jpg' + '/' + clientToken +')' }">
                </div>                
                <div v-else class="item_icon d-inline-block align-middle">
                  <i class="fas fa-list"/>
                </div>
              </b-table-column> 
              <b-table-column field="course_name" sortable :label="$t('lms.Course')" :searchable="searchEnabled" width="50%">
                <span><b>{{props.row.course_name}}</b></span>
              <div class="mt-1 meta">
                <span>{{props.row.course_category_name}}</span>
              </div>
              </b-table-column>
              <b-table-column field="course_started_date" sortable :label="$t('insights.Started_on')" width="25%">
                <div class="d-flex align-items-center count">
                  <span>{{props.row.course_started_date}}</span>
                </div>
              </b-table-column>
              <b-table-column field="course_finished_date" sortable :label="$t('insights.Finished_on')" width="25%">
                <div class="d-flex align-items-center count">
                  <span>{{props.row.course_finished_date}}</span>
                </div>
              </b-table-column>
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <span>{{$t('insights.No_course_attended')}}</span>
              </div>
            </template>
          </b-table>
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'LeaderboardTable',
  props: ['leaderboard'],
  components: {
    userProfilePopover
  },
  watch: {
    $props: {
      handler() {
        if(this.leaderboard) {
          this.leaderboardData = Object.values(this.leaderboard);
          // Check if the pagination should be activated
          (this.leaderboardData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      leaderboardData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 5,
      searchEnabled: false,
      defaultSortDirection: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },  	
  mounted:function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>
